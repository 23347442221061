import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
    sidenav = [
        {
            label: 'Documentation',
            link: '/',
            icon: 'description'
        },
        {
            label: 'Contact',
            link: 'http://www.codershood.info/contact',
            icon: 'contacts'
        },
        {
            label: 'More Modules',
            icon: 'more',
            items: [
                {
                    label: 'Angular Material Multilevel Menu',
                    link: '/',
                    icon: 'menu'
                },
                // {
                //   label: 'Angular Swipe to Delete',
                //   link: '/',
                //   icon: 'delete_sweep'
                // }
            ]
        }
    ];
    constructor() { }

    ngOnInit() {
    }

    selectedItem(selectedData) {
        if (selectedData['icon'] === 'contacts') {
            window.location.href = selectedData['link'];
        } else if (selectedData['icon'] === 'delete_sweep') {

        }
    }

}
