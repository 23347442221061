import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { MaterialsModule } from './../modules/materials.module';

@NgModule({
  imports: [
    CommonModule,
    NgMaterialMultilevelMenuModule,
    MaterialsModule
  ],
  declarations: [HeaderComponent, FooterComponent, MenuComponent],
  exports: [HeaderComponent, FooterComponent, MenuComponent]
})
export class ComponentsModule { }
